import React, { Dispatch, SetStateAction } from 'react';
import { css } from 'aphrodite/no-important';
import { useIntl } from 'react-intl';
import { SegmentedButton } from '@trainline/depot-web';

import messages from './messages';
import styles from './styles';

export enum UsersFilter {
  ALL = 'all',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

interface Props {
  filter: string;
  setFilterButton: Dispatch<SetStateAction<UsersFilter>>;
}

const ManagedGroupUsersFilters = ({ filter, setFilterButton }: Props) => {
  const onClickFilter = (filterType: UsersFilter) => setFilterButton(filterType);
  const { formatMessage } = useIntl();
  return (
    <div className={css(styles.container)}>
      <SegmentedButton
        type="standard"
        segments={Object.values(UsersFilter).map((filterType) => ({
          label: formatMessage(messages[filterType]),
          value: filterType,
          checked: filter === filterType,
          onChange: () => onClickFilter(filterType),
        }))}
      />
    </div>
  );
};

export default ManagedGroupUsersFilters;
