import React, { Dispatch, FunctionComponent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { MenuButton } from '@szhsin/react-menu';

import { BusinessSettings } from '@contactcentre-web/authentication/redux/service';
import * as userSelector from '@contactcentre-web/authentication/redux/selectors';
import {
  getAvailableLocales,
  getCountryFlagLink,
} from '@contactcentre-web/localisation/localisation';
import FormattedLanguage from '@contactcentre-web/common/FormattedLanguage';
import Menu, { ItemsProps } from '@contactcentre-web/common/Menu';
import Button from '@contactcentre-web/common/Button';
import { actions } from '@contactcentre-web/authentication/redux/profile/module';
import {
  actions as redirectActions,
  selectors as redirectSelectors,
} from '@contactcentre-web/header/module';
import type Action from '@contactcentre-web/redux-common/types/Action';
import { getLocale } from '@contactcentre-web/authentication/redux/profile/selectors';
import type State from '@contactcentre-web/redux-common/types/State';
import { selectors as customerOrderSelectors } from '@contactcentre-web/customer-order/module';
import { selectors as featuresSelectors } from '@contactcentre-web/feature-flags/module';

import messages from './messages';
import styles, { menuStylesheet } from './styles';

interface StateProps {
  name: string | null;
  canManageUsers: boolean;
  canEditUsers: boolean;
  canApproveDiscretionaryRefunds: boolean;
  canApproveCompensations: boolean;
  currentLocale: string;
  canSetupNewManagedGroup: boolean;
  canBulkUpload: boolean;
  canRequestUnlimitedRefund: boolean;
  canApproveUnlimitedRefund: boolean;
  isSignOutDisabled: boolean;
  isPaperTicketEnabled: boolean;
  canViewMecClaim: boolean;
  canManageUnmatchedTickets: boolean;
  isvirtualTerminalDebitingEnabled: boolean;
  canRevokeCustomerPassword: boolean;
  businessSettings: BusinessSettings;
  canUseBookingFlow: boolean;
  hasRedirectedSuccessfully: boolean;
}

interface DispatchProps {
  setLocale: (locale: string) => void;
  redirectToBookingFlow: () => void;
}

export const UserMenu: FunctionComponent<StateProps & DispatchProps> = ({
  name,
  canManageUsers,
  canEditUsers,
  canUseBookingFlow,
  canApproveDiscretionaryRefunds,
  canApproveCompensations,
  currentLocale,
  setLocale,
  redirectToBookingFlow,
  hasRedirectedSuccessfully,
  canSetupNewManagedGroup,
  canBulkUpload,
  canRequestUnlimitedRefund,
  canApproveUnlimitedRefund,
  isSignOutDisabled,
  isPaperTicketEnabled,
  isvirtualTerminalDebitingEnabled,
  canViewMecClaim,
  canManageUnmatchedTickets,
  canRevokeCustomerPassword,
  businessSettings,
}) => {
  const history = useHistory();
  const renderUserMenuItems = (condition: boolean, item: ItemsProps): ItemsProps[] =>
    condition ? [item] : [];

  useEffect(() => {
    if (hasRedirectedSuccessfully) {
      window.location.assign('/agent-booking/redirect?discountCards=true');
    }
  }, [hasRedirectedSuccessfully]);

  return (
    <div className={css(styles.menu)} data-test="user-menu-container">
      <Menu
        styleSheet={menuStylesheet}
        testId="user-menu"
        handle={
          <MenuButton data-testid="user-menu-open" className={css(styles.button)}>
            <span className={css(styles.username)}>{name}</span>
            <span className={css(styles.dropdown)}>▾</span>
          </MenuButton>
        }
        items={[
          ...renderUserMenuItems(canManageUsers, {
            'data-test': 'user-menu-add-new-agent',
            className: css(styles.menuItem),
            onClick: () => history.push('/create-new-agent'),
            handle: <FormattedMessage {...messages.addNewAgent} />,
          }),
          ...renderUserMenuItems(canEditUsers || canManageUsers, {
            'data-test': 'users',
            className: css(styles.menuItem),
            handle: <FormattedMessage {...messages.users} />,
            items: [
              ...renderUserMenuItems(canEditUsers, {
                'data-test': 'user-menu-edit-user',
                className: css(styles.menuItem),
                handle: (
                  <Button
                    variant="ghost"
                    onClick={() => history.push('/edit_user')}
                    styleSheet={styles.menuButton}
                  >
                    <FormattedMessage {...messages.editUser} />
                  </Button>
                ),
              }),
              ...renderUserMenuItems(canManageUsers, {
                'data-test': 'user-menu-manage-users',
                className: css(styles.menuItem),
                handle: (
                  <Button
                    variant="ghost"
                    onClick={() => history.push('/managed-group-users')}
                    styleSheet={styles.menuButton}
                  >
                    <FormattedMessage {...messages.manageUsers} />
                  </Button>
                ),
              }),
            ],
          }),
          ...renderUserMenuItems(canViewMecClaim, {
            'data-test': 'user-menu-manual-entry-console',
            className: css(styles.menuItem),
            onClick: () => history.push('/mec-claim-approvals'),
            handle: <FormattedMessage {...messages.manualEntryConsole} />,
          }),
          ...renderUserMenuItems(canManageUnmatchedTickets && isPaperTicketEnabled, {
            'data-test': 'user-menu-paper-ticket-approvals',
            className: css(styles.menuItem),
            onClick: () => history.push('/paper-ticket-approvals'),
            handle: <FormattedMessage {...messages.paperTicketQueue} />,
          }),
          ...renderUserMenuItems(canSetupNewManagedGroup, {
            'data-test': 'setup-managed-group',
            className: css(styles.menuItem),
            onClick: () => history.push('/setup-new-managed-group'),
            handle: <FormattedMessage {...messages.setupManagedGroup} />,
          }),
          ...renderUserMenuItems(canSetupNewManagedGroup, {
            'data-test': 'manage-permission',
            className: css(styles.menuItem),
            onClick: () => history.push('/manage-permission'),
            handle: <FormattedMessage {...messages.managePermission} />,
          }),
          ...renderUserMenuItems(canApproveDiscretionaryRefunds, {
            'data-test': 'user-menu-discretionary-approvals',
            className: css(styles.menuItem),
            onClick: () => history.push('/refund-approvals'),
            handle: <FormattedMessage {...messages.discretionaryApprovals} />,
          }),
          ...renderUserMenuItems(canApproveCompensations, {
            'data-test': 'user-menu-compensation-approvals',
            className: css(styles.menuItem),
            onClick: () => history.push('/compensation-approvals'),
            handle: <FormattedMessage {...messages.compensationApprovals} />,
          }),
          ...renderUserMenuItems(canRequestUnlimitedRefund || canApproveUnlimitedRefund, {
            'data-test': 'user-menu-virtual-terminal',
            handle: <FormattedMessage {...messages.virtualTerminal} />,
            className: css(styles.menuItem),
            items: [
              ...renderUserMenuItems(canRequestUnlimitedRefund, {
                'data-test': 'virtual-terminal-form-credit',
                className: css(styles.menuItem),
                handle: (
                  <Button
                    variant="ghost"
                    onClick={() => history.push('/virtual-terminal-credit')}
                    styleSheet={styles.menuButton}
                  >
                    <FormattedMessage {...messages.virtualTerminalCredit} />
                  </Button>
                ),
              }),
              ...renderUserMenuItems(
                canApproveUnlimitedRefund && isvirtualTerminalDebitingEnabled,
                {
                  'data-test': 'virtual-terminal-form-debit',
                  className: css(styles.menuItem),
                  handle: (
                    <Button
                      variant="ghost"
                      onClick={() => history.push('/virtual-terminal-debit')}
                      styleSheet={styles.menuButton}
                    >
                      <FormattedMessage {...messages.virtualTerminalDebit} />
                    </Button>
                  ),
                }
              ),
              ...renderUserMenuItems(canApproveUnlimitedRefund, {
                'data-test': 'virtual-terminal-approvals',
                className: css(styles.menuItem),
                handle: (
                  <Button
                    variant="ghost"
                    onClick={() => history.push('/virtual-terminal-approvals')}
                    styleSheet={styles.menuButton}
                  >
                    <FormattedMessage {...messages.virtualTerminalApprovals} />
                  </Button>
                ),
              }),
            ],
          }),
          ...renderUserMenuItems(canRequestUnlimitedRefund || canApproveUnlimitedRefund, {
            'data-test': 'user-menu-tracs-onaccount',
            handle: <FormattedMessage {...messages.tracsOnAccount} />,
            className: css(styles.menuItem),
            items: [
              ...renderUserMenuItems(canRequestUnlimitedRefund, {
                'data-test': 'tracs-onaccount-refunds',
                className: css(styles.menuItem),
                handle: (
                  <Button
                    variant="ghost"
                    onClick={() => history.push('/tracs-onaccount-refunds')}
                    styleSheet={styles.menuButton}
                  >
                    <FormattedMessage {...messages.tracsOnAccountRefunds} />
                  </Button>
                ),
              }),
              ...renderUserMenuItems(canApproveUnlimitedRefund, {
                'data-test': 'tracs-onaccount-approvals',
                className: css(styles.menuItem),
                handle: (
                  <Button
                    variant="ghost"
                    onClick={() => history.push('/tracs-onaccount-approvals')}
                    styleSheet={styles.menuButton}
                  >
                    <FormattedMessage {...messages.tracsOnAccountApprovals} />
                  </Button>
                ),
              }),
            ],
          }),
          ...renderUserMenuItems(canBulkUpload, {
            'data-test': 'user-menu-bulk-upload',
            className: css(styles.menuItem),
            onClick: () => history.push('/bulk-uploads'),
            handle: <FormattedMessage {...messages.bulkUpload} />,
          }),
          ...renderUserMenuItems(
            canUseBookingFlow && businessSettings?.sncfDiscountCardRetailFlowEnabled,
            {
              'data-test': 'user-menu-purchase-discount-card',
              className: css(styles.menuItem),
              onClick: () => redirectToBookingFlow(),
              handle: <FormattedMessage {...messages.purchaseDiscountCard} />,
            }
          ),
          {
            'data-test': 'ui-language',
            handle: <FormattedMessage {...messages.locale} />,
            className: css(styles.menuItem),
            items: getAvailableLocales().map((locale) => ({
              'data-test': locale,
              handle: (
                <Button
                  variant="ghost"
                  styleSheet={styles.localeContainer}
                  onClick={() => setLocale(locale)}
                >
                  <div
                    className={css(
                      styles.selectedLocaleContainer,
                      locale === currentLocale && styles.selectedLocale
                    )}
                  />
                  <img src={getCountryFlagLink(locale)} alt={locale} width="24" height="18" />
                  <FormattedLanguage styleSheet={styles.localeName} lang={locale} inLang={locale} />
                </Button>
              ),
              className: css(styles.menuItem),
            })),
          },
          ...renderUserMenuItems(canRevokeCustomerPassword, {
            'data-test': 'user-menu-customers-revocation',
            className: css(styles.menuItem),
            onClick: () => history.push('/customers-revocation'),
            handle: <FormattedMessage {...messages.customersRevocation} />,
          }),
          ...renderUserMenuItems(!isSignOutDisabled, {
            'data-test': 'user-menu-signout',
            className: css(styles.menuItem),
            onClick: () => window.location.assign('/connect/logout'),
            handle: <FormattedMessage {...messages.signOut} />,
          }),
        ]}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  name: userSelector.getUsername(state),
  canManageUsers: userSelector.canManageUsers(state),
  canEditUsers: userSelector.canEditUsers(state),
  canUseBookingFlow: userSelector.canUseBookingFlow(state),
  canApproveDiscretionaryRefunds: userSelector.canApproveDiscretionaryRefunds(state),
  canApproveCompensations: userSelector.canApproveCompensations(state),
  currentLocale: getLocale(state),
  canSetupNewManagedGroup: userSelector.canSetupNewManagedGroup(state),
  canBulkUpload: userSelector.canBulkUpload(state),
  canRequestUnlimitedRefund: userSelector.canRequestUnlimitedRefund(state),
  canApproveUnlimitedRefund: userSelector.canApproveUnlimitedRefund(state),
  isSignOutDisabled: userSelector.selectSignoutDisabled(state),
  canViewMecClaim: userSelector.canViewMecClaim(state),
  isPaperTicketEnabled: featuresSelectors.isPaperTicketEnabled(state),
  canManageUnmatchedTickets: userSelector.canManageUnmatchedTickets(state),
  isvirtualTerminalDebitingEnabled: featuresSelectors.isvirtualTerminalDebitingEnabled(state),
  canRevokeCustomerPassword: userSelector.canRevokeCustomerPassword(state),
  businessSettings: customerOrderSelectors.getBusinessSettings(state),
  hasRedirectedSuccessfully: redirectSelectors.redirectToBookingFlowSuccess(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLocale: (locale: string) => dispatch(actions.setLocale(locale)),
  redirectToBookingFlow: () => dispatch(redirectActions.redirectToBookingFlowAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
