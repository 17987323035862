import { defineMessages } from 'react-intl';

export default defineMessages({
  journeyTab: {
    id: 'OrderDetails.journeyTab',
    defaultMessage: 'Journey',
  },
  paymentSummaryTab: {
    id: 'OrderDetails.paymentSummary',
    defaultMessage: 'Payment summary',
  },
  orderHistoryTab: {
    id: 'OrderDetails.orderHistoryTab',
    defaultMessage: 'Order history',
  },
});
