import React from 'react';
import { css } from 'aphrodite/no-important';

import PageContainer from '@contactcentre-web/common/PageContainer';

import styles from './styles';

const PaymentSummary = () => (
  <PageContainer>
    <div className={css(styles.container)}>
      <h3>Payment Summary</h3>
    </div>
  </PageContainer>
);

export default PaymentSummary;
