import React from 'react';
import { css } from 'aphrodite/no-important';
import { LinkButton, Snackbar, useSnackbar } from '@trainline/depot-web';
import { useIntl } from 'react-intl';

import { LOCALE_CODES } from '@contactcentre-web/localisation/locales';

import LanguageInfo from './LanguageInfo/LanguageInfo';
import StatusInfo from './StatusInfo/StatusInfo';
import styles from './styles';
import InfoRow from './InfoRow';
import ActionsMenu from './ActionsMenu';
import messages from './messages';

interface Address {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  postcode: string;
  countryCode: string;
}

interface Props {
  fullWidth?: boolean;
  forename: string;
  surname: string;
  address: Address;
  email: string;
  customerPreferredLanguage: LOCALE_CODES;
  customerId: string;
  canNavigateToBookings: boolean;
  currentLocale: LOCALE_CODES;
  managedGroupNumber: number;
  canEditCustomerProfile: boolean;
  corporateReference?: string;
  clientReference?: string;
  corporateName?: string;
  migratedDate?: string;
}

const addressKeys = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressLine4',
  'addressLine5',
  'postcode',
  'countryCode',
];

const formatAddress = (obj: Address, keys: string[]) =>
  keys.reduce(
    (prev, cur) =>
      `${prev}${obj[cur as keyof Address] ? `${prev && ', '}${obj[cur as keyof Address]}` : ''}`,
    ''
  );

const CustomerInfo = ({
  fullWidth = true,
  forename,
  surname,
  address,
  email,
  customerPreferredLanguage,
  customerId,
  canNavigateToBookings,
  currentLocale,
  managedGroupNumber,
  canEditCustomerProfile,
  corporateReference,
  clientReference,
  corporateName,
  migratedDate,
}: Props) => {
  const { snackbarState, hideSnackbarHandler, showSnackbarHandler } = useSnackbar();
  const shouldDisplayStatus = managedGroupNumber < 100;
  const { formatDate, formatMessage } = useIntl();
  const shouldRenderActionMenu = canEditCustomerProfile;
  return (
    <div className={css(styles.container, fullWidth && styles.fullWidth)}>
      <div className={css(styles.header)}>
        <h3>
          {forename} {surname}
        </h3>
        <div className={css(styles.rightSection)}>
          {shouldDisplayStatus && <StatusInfo />}
          <LanguageInfo
            preferredLanguage={customerPreferredLanguage}
            currentLocale={currentLocale}
          />
        </div>
      </div>
      <div className={css(styles.details)}>
        <InfoRow type={messages.email} value={email} />
        <InfoRow type={messages.address} value={formatAddress(address, addressKeys)} />

        <InfoRow
          type={messages.migrationDate}
          value={
            migratedDate
              ? formatDate(migratedDate, {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                  timeZone: 'UTC',
                })
              : undefined
          }
        />

        <InfoRow type={messages.corporateName} value={corporateName} />
        <InfoRow type={messages.clientReference} value={clientReference} />
        <InfoRow type={messages.corporateReference} value={corporateReference} />
      </div>
      <div className={css(styles.actions)}>
        <LinkButton
          text={formatMessage(messages.savedPassengers)}
          size="small"
          buttonType="tertiary"
          href={`/customers/${customerId}/passengers`}
        />
        {canNavigateToBookings && (
          <LinkButton
            text={formatMessage(messages.viewBookings)}
            size="small"
            buttonType="tertiary"
            href={`/customers/${customerId}/bookings`}
          />
        )}
        {shouldRenderActionMenu && (
          <ActionsMenu
            canEditCustomerProfile={canEditCustomerProfile}
            forename={forename}
            surname={surname}
            email={email}
            customerPreferredLanguage={customerPreferredLanguage}
            customerId={customerId}
            showSnackbarHandler={showSnackbarHandler}
            fullWidth={fullWidth}
          />
        )}
      </div>
      <Snackbar
        snackbarState={snackbarState}
        hideSnackbarHandler={hideSnackbarHandler}
        label={formatMessage(messages.customerProfileUpdated)}
        className={css(styles.notificationBar)}
      />
    </div>
  );
};

export default CustomerInfo;
