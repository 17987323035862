import { StyleSheet } from 'aphrodite/no-important';
import { spacers, borderRadius, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    marginBlock: spacers.dp_s48,
    borderRadius: borderRadius.dp_r4,
    border: `1px solid ${palettes.dp_app_border_base}`,
    padding: spacers.dp_s24,
  },
  table: {
    overflow: 'hidden',
    marginTop: spacers.dp_s24,
    borderRadius: borderRadius.dp_r8,
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    border: `1px solid ${palettes.dp_app_border_base}`,
    ':nth-child(1n) thead': {
      backgroundColor: palettes.dp_comp_table_row,
      height: '60px',
      ':nth-child(1n) th': {
        paddingInline: spacers.dp_s12,
        textAlign: 'left',
      },
    },
    ':nth-child(1n) td': {
      borderBottom: `1px solid ${palettes.dp_app_border_base}`,
      paddingInline: spacers.dp_s12,
      height: '60px',

      ':first-child span': {
        width: '100px',
      },

      ':nth-child(2) span': {
        width: '200px',
      },
    },
    ':nth-child(1n) tr:last-child td': {
      borderBottom: 0,
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  role: {
    marginBottom: spacers.dp_s12,
  },
  notWrap: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
