import React from 'react';
import { css } from 'aphrodite/no-important';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tabs } from '@trainline/depot-web';
import { useIntl } from 'react-intl';

import OrderHistory from '@contactcentre-web/order-history/OrderHistory';

import Journey from '../../components/Journey';
import PaymentSummary from '../../components/PaymentSummary';
import CustomerInfo from '../../components/CustomerInfo';
import OrderInfo from '../../components/OrderInfo';
import OrderActions from '../../components/OrderActions';

import messages from './messages';
import styles from './styles';
import { orderDetailsSelector } from './selectors';

const OrderDetails = () => {
  const { formatMessage } = useIntl();
  const { currentLocale, managedGroupNumber, canEditCustomerProfile, selectedCustomer } =
    useSelector(orderDetailsSelector);
  const {
    params: { customerId },
  } = useRouteMatch<{ customerId: string }>();
  const location = useLocation();
  const history = useHistory();
  const selectedTab = new URLSearchParams(location.search).get('tab') ?? undefined;

  const isCustomerBookingsPage = useRouteMatch({
    path: '/customers/:customerId/bookings',
    exact: true,
  });

  const onTabChange = (key: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', key);
    history.push({ search: searchParams.toString() });
  };

  return (
    <>
      <OrderActions />
      <div className={css(styles.pageHeaderWrapper)}>
        <CustomerInfo
          customerId={customerId}
          canNavigateToBookings={!isCustomerBookingsPage}
          expandHeader={() => null}
          currentLocale={currentLocale}
          managedGroupNumber={managedGroupNumber}
          canEditCustomerProfile={canEditCustomerProfile}
          fullWidth={false}
          {...selectedCustomer}
        />
        <OrderInfo />
      </div>

      <Tabs
        items={[
          {
            key: 'customer-order-journey-tab',
            label: formatMessage({ ...messages.journeyTab }),
            content: <Journey />,
          },
          {
            key: 'customer-order-payment-summary',
            label: formatMessage({ ...messages.paymentSummaryTab }),
            content: <PaymentSummary />,
          },
          {
            key: 'customer-order-history',
            label: formatMessage({ ...messages.orderHistoryTab }),
            content: <OrderHistory />,
          },
        ]}
        onTabChange={onTabChange}
        className={css(styles.tabs)}
        defaultActiveTabKey={selectedTab}
      />
    </>
  );
};

export default OrderDetails;
