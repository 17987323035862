import { StyleSheet } from 'aphrodite/no-important';
import { spacers } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    marginTop: spacers.dp_s16,
    columnGap: spacers.dp_s8,
    width: '250px',
  },
});
